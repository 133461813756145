import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import FsLightbox from "fslightbox-react";

// CSS styles
import "./agenda.scss";

// Images
import agenda from "../../public/agenda/agenda_2024.jpg";

export default ({ className, id }) => {
  // const containerRef = React.createRef();
  // const [toggler, setToggler] = useState(false);
  // const [photoIndex, setPhotoIndex] = useState(-1);
  // const [photos, setPhotos] = useState([]);

  // useEffect(() => {
  //   setPhotos(Object.values(pictures).sort());
  // }, [pictures]);

  return (
    // <section className={className} id={id} ref={containerRef}>
    <section className={className} id={id}>
      <div className="content--wrapper">
        <div
          id="agenda--wrapper"
          className="content--row--full content--row--dark"
        >
          <img src={agenda} alt="" />
        </div>
      </div>
    </section>
  );
};
