import React, { useEffect, useState } from "react";

// CSS styles
import "./team.scss";

// Pictures
const pictures_list = require(`../../public/team/*.jpg`);

// Team members
const _teamMembers = require("./members");

// Icons
import { FaFacebook, FaInstagram, FaSoundcloud, FaMusic } from "react-icons/fa";

const _SOCIAL_NETWORKS = {
  facebook: FaFacebook,
  instagram: FaInstagram,
  soundcloud: FaSoundcloud,
  playlist: FaMusic,
};

export default ({ className, id }) => {
  return (
    <section className={className} id={id}>
      <div className="content--wrapper">
        <div className="page--separator--m"></div>
        {/* <div className="team--wrapper"> */}
        <div className="team--title">
          <h2 className="text--light">L'équipe</h2>
        </div>
        <div className="team--members--list">
          {_teamMembers.map((member, index) => {
            return (
              <div className="team--members--item" key={index}>
                {/* <img src={member.picture} alt={`${member.firstname} ${member.lastname}`} /> */}
                <img
                  src={
                    member.picture && pictures_list[member.picture]
                      ? pictures_list[member.picture]
                      : "https://via.placeholder.com/290x390"
                  }
                  alt={`${member.firstname} ${member.lastname}`}
                />
                <div className="team--members--legend">
                  <h3>{`${member.firstname} ${member.lastname}`} </h3>
                  <ul>
                    {member.role &&
                      member.role.map((role, key) => {
                        return <li key={key}>{role}</li>;
                      })}
                  </ul>
                  <div className="team--members--rs">
                    {member.rs &&
                      member.rs.map((network, key) => {
                        const IconComp = _SOCIAL_NETWORKS[network.name];
                        return (
                          <a key={key} href={network.url} target="_blank">
                            <IconComp />
                          </a>
                        );
                      })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {/* </div> */}
      </div>
    </section>
  );
};
