import React, { useEffect, useState } from "react";

// CSS styles
import "./contact.scss";

// Images

// Icons
import { FaFacebook, FaInstagram, FaPhone, FaVoicemail } from "react-icons/fa";

export default ({ className, id }) => {
  return (
    <section className={className} id={id}>
      <div className="content--wrapper">
        <h1>Nous contacter</h1>
        <p>123 Main Street, Anytown, USA</p>
        <p>
          <FaPhone /> qdsqd
        </p>
        <p>
          <FaVoicemail /> qsdfqsd
        </p>
        <p>
          <FaInstagram />
        </p>
        <div className="socials">
          <a href="#" target="_blank">
            <FaFacebook />
          </a>
          <a href="#" target="_blank">
            <FaInstagram />
          </a>
        </div>
      </div>
    </section>
  );
};
