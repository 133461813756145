import React, { useState } from "react";
import { Divide as Hamburger } from "hamburger-react";

// CSS styles
import "./menu.scss";
import { useEffect } from "react";

// Images
// import at from "../../public/contact/at.svg";
import arrow from "../../public/svg/arrow.svg";

export default ({ ...props }) => {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      // element.removeEventListener('scroll', someFunction);
    }
  }, [isOpen]);

  return (
    <nav id="mainmenu" {...props}>
      <label
        id="member--btn-back"
        htmlFor={`r-member--reset`}
        onClick={(e) => e.target.classList.remove("active")}
      >
        <img src={arrow} /> L'équipe
      </label>
      <Hamburger toggled={isOpen} toggle={setOpen} color="#FEFEFE" size={24} />
      <ul className={isOpen ? "open" : ""}>
        <li>
          <a href="#hero" onClick={() => setOpen(false)}>
            Accueil
          </a>
        </li>
        <li>
          <a href="#company" onClick={() => setOpen(false)}>
            La compagnie
          </a>
        </li>
        <li>
          <a href="#projects" onClick={() => setOpen(false)}>
            Les créations
          </a>
        </li>
        <li>
          <a href="#agenda" onClick={() => setOpen(false)}>
            Agenda
          </a>
        </li>
        <li>
          <a href="#team" onClick={() => setOpen(false)}>
            L'équipe
          </a>
        </li>
        <li>
          <a href="#footer" onClick={() => setOpen(false)}>
            Nous contacter
          </a>
        </li>
      </ul>
    </nav>
  );
};
