import React from "react";

// CSS styles
import "./company.scss";

import quote from "../../public/svg/quote.svg";

export default ({ className, id }) => {
  return (
    <section className={className} id={id}>
      <div className="content--wrapper">
        <div id="company--quote" className="content--row content--row--dark">
          {/* <div className="content--row--mfull"> */}
          <div className="content--col--right--rfull">
            <p className="text--quote">
              <img className="double_quotes" src={quote} alt="Double quotes" />
              Le Quetzal, l’oiseau tropicale est un véritable symbole de liberté
              puisqu’une fois en captivité, il se laisse mourir plutôt que de
              vivre enfermé.
            </p>
          </div>
        </div>
        <div
          id="company--description"
          className="content--row--mfull content--row--light"
        >
          {/* <div className="content--row--"> */}
          <div className="content--col--left--rfull">
            <div className="image-container"></div>
          </div>
          <div className="content--col--right--rfull">
            <div className="text--company">
              <h1>La compagnie</h1>
              <p>
                <b>Quetzal Art</b> est une compagnie de spectacle vivant fondée
                par <b>Elodie Allary</b> en 2023 dans une volonté de mélanger
                plusieurs arts.
                <br />
                <br />
                D’origine franco-guatémaltèque, Elodie trouve son essence
                artistique à la croisée des cultures. Ainsi, elle crée sa
                compagnie à l’image d’un berceau de plusieurs rencontres
                favorisant l’hybridation. En parallèle de la diffusion de son
                solo « Morpho » ainsi que du ballet pantomime “le Secret de
                Myrto”, la compagnie entame différentes créations en
                collaboration avec d’autres artistes mélangeant danse classique,
                contemporaine, hip-hop, musique, chant et arts visuels.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
