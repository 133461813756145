module.exports = [
  {
    "id": "eall",
    "firstname": "Elodie",
    "lastname": "Allary",
    "role": [" Directrice artistique", "Chorégraphe"],
    "picture": "eall",
    "rs": [
      {
        "name": "facebook",
        "url": "https://www.facebook.com/elodie.allary"
      },
      {
        "name": "instagram",
        "url": "https://www.instagram.com/elodie.allary/"
      }
    ]
  },
  {
    "id": "fcap",
    "firstname": "Franck",
    "lastname": "Caporale",
    "role": ["Co-Chorégraphe", "Danseur"],
    "picture": "fcap",
    "rs": [
      {
        "name": "facebook",
        "url": "https://www.facebook.com/kifran.basterd"
      },
      {
        "name": "instagram",
        "url": "https://www.instagram.com/franck_caporale/"
      }
    ]
  },
  {
    "id": "edob",
    "firstname": "Emmanuel",
    "lastname": "Dobby",
    "role": ["Assistant chorégraphe", "Danseur"],
    "picture": "edob",
    "rs": [
      {
        "name": "facebook",
        "url": "https://www.facebook.com/emmanuel.dobby"
      },
      {
        "name": "instagram",
        "url": "https://www.instagram.com/dcr_emmanueldobby/"
      }
    ]
  },
  {
    "id": "tpal",
    "firstname": "Théo",
    "lastname": "Palfray",
    "role": ["Compositeur"],
    "picture": "tpal",
    "rs": [
      {
        "name": "facebook",
        "url": "https://www.facebook.com/theo.palfray"
      },
      {
        "name": "instagram",
        "url": "https://www.instagram.com/theo_palfray9/"
      },
      {
        "name": "soundcloud",
        "url": "https://soundcloud.com/#"
      }
    ]
  },
  {
    "id": "jevr",
    "firstname": "Jules",
    "lastname": "Evrard",
    "role": ["Compositeur"],
    "picture": "jevr",
    "rs": [
      {
        "name": "instagram",
        "url": "https://www.instagram.com/d.s.t.c.h/"
      },
      {
        "name": "playlist",
        "url": "https://recordu.lnk.to/D0t7Fe"
      }
    ]
  },
  {
    "id": "wpen",
    "firstname": "William",
    "lastname": "Penanhoat",
    "role": ["Régisseur Lumière"],
    "picture": "wpen",
    "rs": [
      {
        "name": "facebook",
        "url": "https://www.facebook.com/william.pnh"
      },
      {
        "name": "instagram",
        "url": "https://www.instagram.com/williampnh/"
      }
    ]
  },
  {
    "id": "jleg",
    "firstname": "Jérémy",
    "lastname": "Legeay",
    "role": ["Développeur", "Assistant Vidéo"],
    "picture": "jleg",
    "rs": []
  },
  {
    "id": "spol",
    "firstname": "Studio",
    "lastname": "Polymorphose",
    "role": ["Photographe"],
    "picture": "spol",
    "rs": [
      {
        "name": "facebook",
        "url": "https://www.facebook.com/sam.polymorphose"
      },
      {
        "name": "instagram",
        "url": "https://www.instagram.com/polymorphose_photo/"
      }
    ]
  }
]
;