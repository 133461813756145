import React, { useEffect, useState } from "react";

// Icons
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";

// CSS styles
import "./footer.scss";

// Images
import bg_footer from "../../public/footer/bg_footer.jpg";
// import img_03 from "../../public/projects/comme_un_echo/gallery/comme_un_echo-03.jpg";

export default ({ className, id }) => {
  return (
    <footer className={className} id={id}>
      <div id="bg--footer">
        <img src={bg_footer} />
      </div>
      {/* <div className="content--wrapper"></div> */}
      <div className="contact--wrapper">
        <p className="title">Nous contacter</p>
        <a
          className="email"
          href="mailto:production@quetzal-art.fr"
          target="_blank"
        >
          production@quetzal-art.fr
        </a>
      </div>

      <div className="socials--wrapper">
        <a
          href="https://www.instagram.com/quetzal_art_company/"
          target="_blank"
        >
          <FaInstagram />
        </a>
        <a
          href="https://www.facebook.com/profile.php?id=100093012414290"
          target="_blank"
        >
          <FaFacebook />
        </a>
        <a href="https://www.youtube.com/@Quetzalart-company" target="_blank">
          <FaYoutube />
        </a>
      </div>
      <div className="subcontact--wrapper">
        <p className="name">Elodie Allary</p>
        <p className="role">Directrice artistique, Chorégraphe</p>
        <a
          className="email"
          href="mailto:elodie.allary@gmail.com"
          target="_blank"
        >
          elodie.allary@gmail.com
        </a>
        <a className="phone" href="tel:0663065560" target="_blank">
          06.63.06.55.60
        </a>
      </div>
      <div className="copyright--wrapper">
        <p>Copyright &copy; 2023 - 2024 Quetzal Art. Tous droits réservés.</p>
      </div>
    </footer>
  );
};
