import React, { useEffect, useState, useRef } from "react";
import { motion, useScroll, useSpring } from "framer-motion";

import Mainmenu from "./mainmenu/Mainmenu";
import Hero from "./hero/Hero";
import Company from "./company/Company";
import Projects from "./projects/Projects";
import Agenda from "./agenda/Agenda";
import Team from "./team/Team";
import Contact from "./contact/Contact";
import Footer from "./footer/Footer";

export default () => {
  const containerRef = useRef(null);

  const { scrollYProgress } = useScroll({ container: containerRef });
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  return (
    <section id="container" ref={containerRef}>
      <motion.div id="progress_bar" style={{ scaleX }} />
      <Mainmenu />
      <Hero className="fullpage" id="hero" />
      <Company className="fullpage" id="company" />
      <div className="page--separator--m"></div>
      <Projects className="fullpage" id="projects" />
      <Agenda className="fullpage" id="agenda" />
      <Team className="fullpage" id="team" />
      {/* <Contact className="fullpage" id="contact" /> */}
      <Footer id="footer" />
    </section>
  );
};
