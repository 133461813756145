import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import FsLightbox from "fslightbox-react";

// CSS styles
import "./projects.scss";

// Project import
// import Comme_un_echo from "./comme_un_echo";

// Images
import logo_comme_un_echo from "../../public/projects/comme_un_echo/logo__comme_un_echo.svg";

// Videos
import teaser from "../../public/projects/comme_un_echo/teaser_comme_un_echo.mp4";
import teaser_placeholder from "../../public/projects/comme_un_echo/placeholder_teaser.jpg";

//Gallery
import tb_pictures from "../../public/projects/comme_un_echo/g/tb/comme_un_echo-*.jpg";
import pictures from "../../public/projects/comme_un_echo/g/comme_un_echo-*.jpg";

export default ({ className, id }) => {
  // const containerRef = React.createRef();
  const [toggler, setToggler] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(-1);
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    setPhotos(Object.values(pictures).sort());
  }, [pictures]);

  return (
    // <section className={className} id={id} ref={containerRef}>
    <section className={className} id={id}>
      <div className="content--wrapper">
        <div id="project--video" className="content--row--full">
          <h1>Les créations</h1>
          <div className="video--wrapper">
            {/* <ReactPlayer url={teaser} /> */}
            <video
              controls
              controlsList="nodownload noremoteplayback noplaybackrate"
              poster={teaser_placeholder}
            >
              <source src={teaser} type="video/mp4" />
            </video>
            <div className="project--title--wrapper">
              <div className="project--title-creation">Création</div>
              <img className="project--title" src={logo_comme_un_echo} alt="" />
              <h5 className="project--subtitle text--primary">
                Duo Néo-Classique / Breakdance / Contemporain
              </h5>
              <div className="project--time">Tout public - 35 min</div>
            </div>
          </div>
        </div>
        <div id="project--description" className="content--row--mfull">
          <div className="content--col--left--rfull">
            <div className="text--project">
              <h3 className="project--title">Comme un Écho</h3>
              <h5 className="project--subtitle text--primary">
                Duo Néo-Classique / Breakdance / Contemporain
              </h5>
              <div className="project--time">Tout public - 35 min</div>
              <p className="project--description">
                Un homme, une femme, deux âmes face à leur solitude, chacune
                d’un coté de la rive d’un lac. Le regard perdu dans les
                profondeurs, ils rêvent d'un être traversant les mêmes
                tourments.
                <br />
                <br /> Un impact, un écho. Une onde de choc telle une trace que
                l’autre remarque au loin. Mais que se passerait-il si l’un était
                l’écho de l’autre, s'échappant chacun de leurs ombres
                entremêlées pour laisser apparaître deux entités qui affirment
                leur singularité ? Le temps d’un rêve illusoire, les deux
                personnages vont se créer un monde fictif où ils se retrouvent
                face à une personne idéale pour partager un instant de
                compréhension mutuelle et de réconfort. Un impact, un écho, un
                rêve créé pour mieux affronter leur réalité. <br />
                <br />
                Dans le souffle de notre monde contemporain, “Comme un Écho” est
                un spectacle au langage chorégraphique hybride mélangeant
                Néo-Classique, Breakdance et Contemporain, dans une création
                musicale originale fusionnant disciplines anciennes et
                actuelles.
              </p>
            </div>
          </div>
          <div className="content--col--right--rfull">
            <div className="image-container"></div>
          </div>
        </div>
        <div
          id="project--gallery--wrapper"
          className="content--row--full content--row--dark"
        >
          <FsLightbox
            toggler={toggler}
            sources={photos}
            slide={photoIndex + 1}
          />
          <h2 className="text--light">Gallerie</h2>
          <div id="project--gallery">
            {photos.length > 0 &&
              photos.map((photo, index) => {
                return (
                  <div key={index} className="project--gallery--img--wrapper">
                    <img
                      key={index}
                      src={tb_pictures[index + 1]}
                      alt={`comme_un_echo-${index + 1}`}
                      onClick={() => {
                        setPhotoIndex(index);
                        setToggler(!toggler);
                      }}
                    />
                  </div>
                );
              })}
          </div>
        </div>
        <div
          id="project--legal-notices--wrapper"
          className="content--row--full content--row--dark"
        >
          {/* <p>
            <b>Direction artistique</b> : Elodie Allary
          </p>
          <p>
            <b>Chorégraphie et interprétation</b> : Elodie Allary et Franck
            Caporale
          </p>
          <p>
            <b>Musique</b> : Théo Palfray et Jules Evrard
          </p>
          <p>
            <b>Création Lumière</b> : William Penanhoat
          </p> */}
          <b>Direction artistique</b>Elodie Allary
          <b>Chorégraphie et interprétation</b>Elodie Allary et Franck Caporale
          <b>Musique</b>Théo Palfray et Jules Evrard
          <b>Création Lumière</b>William Penanhoat
        </div>
      </div>
    </section>
  );
};
