import React from "react";

// CSS styles
import "./hero.scss";

// Images
import bg_hero_logo from "../../public/hero/bg_hero_logo.png";

// Videos
import teaser from "../../public/hero/teaser.mp4";

export default ({ className, id }) => {
  return (
    <section className={className} id={id}>
      <div className="content--wrapper">
        <img src={bg_hero_logo} />
        <div className="square--1"></div>
        <div className="square--2"></div>
        <div className="hero--bg" />
        <video autoPlay loop muted>
          <source src={teaser} type="video/mp4" />
        </video>
      </div>
    </section>
  );
};
